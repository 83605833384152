import axios from '@/axios.js'

export default {
  fetchRoles ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/role')
        .then(({data: response}) => {
          commit('SET_ROLES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchRole (context, roleId) {
    return new Promise((resolve, reject) => {
      axios.get(`/role/${roleId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeData (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/role', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateRole (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/role/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyData ({ commit }, roleId) {
    return new Promise((resolve, reject) => {
      axios.delete(`role/${roleId}`)
        .then((response) => {
          commit('REMOVE_RECORD', roleId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
